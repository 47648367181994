import React from "react";
import AppBarPage from "../components/AppBarPage";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { topAppBarHeight } from "../constants/BaseConstants";
import { Link, List, ListItem, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { accordionData } from "../constants/BooksData";

const verticalOffset = 40;

const pageTitle = "Books";

const container = {
  maxWidth: "750px",
  margin: "auto",
  marginTop: topAppBarHeight + verticalOffset + "px",
  padding: "0px 14px 0px 14px",
};

const title = {
  fontWeight: "bold",
  margin: "0.8em 0em 0.8em 0em",
  fontSize: "1.3rem",
};

const bold = {
  fontWeight: "bold",
};

const paragraph = {
  margin: "0em 0em 0em 0em",
  fontSize: "1rem",
};

const accordions = {
  margin: "1.5em 0 0 0",
};

const link = {
  dosplay: "block",
  textDecoration: "none",
};

function Contact(): JSX.Element {
  return (
    <Box>
      <AppBarPage title={pageTitle} />
      <Box sx={container}>
        <Typography sx={title} variant="h1">
          SureQuran Books
        </Typography>
        <Typography sx={paragraph}>
          <Box sx={bold} component="span">
            God Edition
          </Box>{" "}
          - For readers who prefer a translation that uses &rsquo;God&rsquo; to denote the Creator.
        </Typography>
        <Typography sx={paragraph}>
          <Box sx={bold} component="span">
            Allah Edition
          </Box>{" "}
          - For readers who prefer a translation that uses &rsquo;Allah&rsquo; to denote the Creator.
        </Typography>
        <Box sx={accordions}>
          {/*Accordion 1 */}
          {accordionData.map(accordion => (
            <Accordion key={accordion.accordionTitle}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>{accordion.accordionTitle}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {accordion.items.map(book => (
                    <ListItem key={book.bookUrl}>
                      <Link sx={link} href={book.bookUrl} target="_blank" rel="noopener noreferrer">
                        {book.bookTitle}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Box m={8}></Box>
      </Box>
      <Helmet>
        {/* meta tags */}
        <title>Quran in Hardcopy, Kindle | English, Bilingual - SureQuran</title>
        <meta
          name="description"
          content="Buy SureQuran's AI-optimized Quran on Amazon: clear, pure, precise. Available in paperback, hardcover & Kindle, in English & bilingual."
        />
        <meta name="author" content="Talal Itani" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    </Box>
  );
}

export default Contact;
