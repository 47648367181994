export const accordion1Data = {
  accordionTitle: "English Only - God Edition",
  items: [
    {
      bookTitle: "Paperback",
      bookUrl:
        "https://www.amazon.com/dp/B0CTH584RJ?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=8c25f990d43a03e911801e81993f9c57&camp=1789&creative=9325",
    },
    {
      bookTitle: "Hardcover",
      bookUrl:
        "https://www.amazon.com/dp/B0CTKD2Z8K?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=35fe5ccaf8472828fb109aa48754db39&camp=1789&creative=9325",
    },
    {
      bookTitle: "Kindle",
      bookUrl:
        "https://www.amazon.com/dp/B0CTHXDYNF?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=2ddeaaee64bc7419c9a06edfcf040eca&camp=1789&creative=9325",
    },
  ],
};

export const accordion2Data = {
  accordionTitle: 'English Only - God Edition (8.5" x 11")',
  items: [
    {
      bookTitle: "Paperback",
      bookUrl:
        "https://www.amazon.com/dp/B0CTH4ZV6D?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=f645b9d2d8691cadb66857bc005b97b8&camp=1789&creative=9325",
    },
    {
      bookTitle: "Kindle",
      bookUrl:
        "https://www.amazon.com/dp/B0CTHXDYNF?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=2ddeaaee64bc7419c9a06edfcf040eca&camp=1789&creative=9325",
    },
  ],
};

export const accordion3Data = {
  accordionTitle: "English Only - Allah Edition",
  items: [
    {
      bookTitle: "Paperback",
      bookUrl:
        "https://www.amazon.com/dp/B0CTH6HJYN?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=7f062ff30b704da8c3497e49c7227a9e&camp=1789&creative=9325",
    },
    {
      bookTitle: "Hardcover",
      bookUrl:
        "https://www.amazon.com/dp/B0CTKDSBD2?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=7e6cff43d4d11f52e8e52d48a35d83ca&camp=1789&creative=9325",
    },
    {
      bookTitle: "Kindle",
      bookUrl:
        "https://www.amazon.com/dp/B0CTHWT859?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=4f99fae853bf7e16e09f342fe64fa1a4&camp=1789&creative=9325",
    },
  ],
};

export const accordion4Data = {
  accordionTitle: "English with Arabic - Allah Edition",
  items: [
    {
      bookTitle: "Paperback",
      bookUrl:
        "https://www.amazon.com/dp/B0CTH5JD19?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=f1624cfcfe123c9bf5c3af9ff0cb77ba&camp=1789&creative=9325",
    },
    {
      bookTitle: "Kindle",
      bookUrl:
        "https://www.amazon.com/dp/B0CTJ316L8?&_encoding=UTF8&tag=qurainengl-20&linkCode=ur2&linkId=aded7d8f45aa2f50470e4fd89434ba2b&camp=1789&creative=9325",
    },
  ],
};

export const accordionData = [accordion1Data, accordion2Data, accordion3Data, accordion4Data];
